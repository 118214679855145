
<template>
  <div class="background_block">
    <div class="punish_article dutyList_detail">
      <!-- 标题 -->
      <div class="punish_article_title">
        <h3>{{ articleList.name }}（{{ articleList.fileNum }}）</h3>
        <span>发布时间：{{ articleList.publishTime }}</span>
        <span>来源：{{ articleList.resource }}</span>
        <p></p>
      </div>
      <!-- 列表 -->
      <div class="punish_article_content">
        <div>
          <span class="paragraph1" v-html="articleList.content">
            <!-- {{articleList}} -->
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getrolesDetailApi } from "@/api/rolesDetailApi";
export default {
  data() {
    return {
      articleList: [],
    };
  },
  async mounted() {
    await getrolesDetailApi({
      params: {
        reguId: this.$route.query.id,
      },
    }).then((res)=>{
      console.log(res.data.data);
      this.articleList = res.data.data;
    })

  },
};
</script>
<style scoped>
@import "../../../static/css/article.css";
</style>
