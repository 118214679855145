
<template>
  <div class="roles_box">
    <div>
      <!-- 欢迎使用 -->
      <div :class="{'main_background':mainCss,'main_background1':!mainCss}">
        <!-- <p id="punish_p">聚焦规章制度，协助业务制度感知</p>
        <p>了解更多的规章制度，助力业务分析</p> -->
        <p id="punish_p">规章制度</p>
        <p>监管规定，应知应会</p>
        <div class="punish_select">
          <el-select v-model="value" placeholder="最新" @change="yearChange">
            <el-option
              v-for="(item, index) in yearInfo"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-button  type="primary" id="backButton" @click="filtrate">
            <img  src="@/assets/image/icon/shaixuan.svg" alt=""/>&nbsp;&nbsp;高级筛选</el-button>
        </div>

        <div class="main18 flex-col"></div>
        <div style="margin-top: 60px">
          <el-form :model="ruleForm" label-width="80px"  label-position="left">
            <el-form-item
                    :label="nape.name"
                    prop="type"
                    class="backlabel"
                    v-for="(nape, index) in search_tagList"
                    :key="nape.tagId"
            >
              <el-checkbox v-model="nape.checkAll"
                      @change="handleCheckAllChange(index)">全部</el-checkbox>
              <el-checkbox-group
                      v-model="nape.checkedLine"
                      @change=" (value) => { handleCheckedChange(value, index); } ">
                <el-checkbox
                        v-for="(item, index) in nape.tagDetList"
                        :label="item.tagDetId"
                        :key="index"
                >{{ item.detName }}</el-checkbox
                >
              </el-checkbox-group>

              <span v-if="index == 0" @click="filtrate" style="position: absolute;right: 20px"
                >收起 <i class="el-icon-arrow-up"></i>
              </span>
            </el-form-item>

            <el-form-item class="punish_search" style="margin-left: -4.85rem;padding-bottom: 30px">
              <el-button @click="reset" style="border: 1px solid #2696FF;font-weight: bolder;color: #2696FF;">重置</el-button>
              <el-button @click="yearChange" style="background-color: #2696FFFF;color: #FFFFFF;border: 1px solid #2696FF;">确定</el-button>
            </el-form-item>
          </el-form>

        </div>

      </div>

    </div>

    <div class="punish_detail_box">
      <!-- 列表 -->
      <div class="punish_detail">
        <div class="punish_detail_header">
          <!-- <img src="@/assets/image/role/rolesIcon.png1.png" alt="" /> -->
          <img src="@/assets/image/role/rolesIcon.png" alt="" />
          <span>规章制度</span>
        </div>
        <ul class="punish_detail_list">
          <li
            v-for="(item, index) in rolesList"
            :key="index"
            @click="goDetail(item.id)"
          >
            <span>{{ index + 1 }}</span>
            <span>{{ item.name }}（{{ item.fileNum }}）</span>
            <span>{{ item.publishTime }}</span>
          </li>
        </ul>
        <!-- 分页 -->
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="prev,pager,next,sizes,jumper"
            :total="total"
            :pager-count="9"
            background
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDutyListApi } from "@/api/dutyListApi";
export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "2",
      list: [],
      value: "",
      year: "",
      mainCss:true,
      yearInfo: [],
      rolesList: [],
      total: 0,
      //高级筛选下列表
      search_tagList: [],
      //多选
      checkAll: [],
      checkedLine: [],
      // 表单校验
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },

    };
  },
  async mounted() {
    this.id = this.$route.query.id;
    await getDutyListApi({
      lineId: this.id,
      type: 2,
    }).then((res) => {
      this.yearInfo = res.data.data.yearInfo;
      this.rolesList = res.data.data.regulation;
      this.total = res.data.data.total;
      this.search_tagList = res.data.data.tagList;
      this.search_tagList.forEach((item) => {
        this.$set(item, "checkAll", false);
        this.$set(item, "checkedLine", []);
        item.val = true;
      });
    });
    document.getElementsByClassName(
      "el-pagination__jump"
    )[0].childNodes[0].nodeValue = "跳至";
  },
  methods: {
    //规章制度详情
    goDetail(id) {
      this.$router.push({ name: "detail", query: { id } });
    },
    async yearChange() {
      this.year = this.value;
      var detlist = [];
      [].concat.apply([],this.checkedLine).map((value) =>{
        if(!!value){detlist.push(value)}
      })
      await getDutyListApi({
        type: 2,
        yearType: this.year == "最新" ? "" : this.year,
        tagDetIdList: detlist,
      }).then((res) => {
        this.rolesList = res.data.data.regulation;
        this.total = res.data.data.total;
        this.yearInfo = res.data.data.yearInfo;
        this.mainCss = true;
      });
    },

    async handleSizeChange(val) {
      var detlist = [];
      [].concat.apply([],this.checkedLine).map((value) =>{
        if(!!value){detlist.push(value)}
      })
      await getDutyListApi({
        lineId: this.id,
        type: 2,
        pageSize: val,
        tagDetIdList: detlist,
      }).then((res) => {
        this.rolesList = res.data.data.regulation;
      });
    },
    async handleCurrentChange(val) {
      var detlist = [];
      [].concat.apply([],this.checkedLine).map((value) =>{
        if(!!value){detlist.push(value)}
      })
      await getDutyListApi({
        lineId: this.id,
        type: 2,
        pageNum: val,
        tagDetIdList: detlist,
      }).then((res) => {
        this.rolesList = res.data.data.regulation;
      });
    },
    // 高级筛选展开
    filtrate() {
      if(this.mainCss){
        this.mainCss = false
      }else{
        this.mainCss = true
      }
    },

    handleCheckAllChange(index) {

      console.info(index);
      console.info(this.search_tagList[index].val);
      if (this.search_tagList[index].val) {
        this.search_tagList[index].checkedLine = [];
        this.search_tagList[index].tagDetList.forEach((item) => {
          this.search_tagList[index].checkedLine.push(item.tagDetId);
        });
        this.search_tagList[index].val = false;
        this.checkedLine[index] = this.search_tagList[index].checkedLine
        //this.checkedLine.push(this.search_tagList[index].checkedLine);
      } else {
        this.search_tagList[index].checkedLine = [];
        this.search_tagList[index].val = true;
        this.checkedLine[index] = [];
       // this.checkedLine.splice(index,1);
      }
      console.info(this.checkedLine);
    },
    // 多选框
    handleCheckedChange(value, index) {
      this.checkedLine = [];
      let Count = value.length;
      this.search_tagList[index].checkAll =
              Count === this.search_tagList[index].tagDetList.length;
      this.search_tagList.forEach((item) => {
        this.checkedLine.push(item.checkedLine);
      });
    },
    // 表单重置
    reset() {
      this.checkedLine = [];
      this.checkAll = [];
      this.search_tagList.map((item) => {
        item.checkedLine = [];
        item.checkAll = false;
        item.val = true;
      });
    },
  },
};
</script>
<style scoped>
@import "../../static/css/page.css";
@import "../../static/css/headerBanner.css";
.punish_detail {
  height: 550px;
  position: absolute;
  z-index: 0;
  left: 1.78rem;
  top: 274px;
}

.el-button--primary {
  background-color: transparent !important;
  color: #2696FF !important;
  font-size: 14px;
  height: 0.1667rem;
  margin-left: 10px;
  padding-top: 4px;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #2696FF;
}
.main_background {
  background: url("../../assets/image/role/regu.png") no-repeat;
  background-size: 100% ;
  width: 96%;
  height: 1.125rem ;
  overflow: hidden;
  text-align: left;
  position: absolute;
  z-index: 1;
}
.main_background1 {
  background: url("../../assets/image/role/regu.png") no-repeat;
  background-size: 100% ;
  background-color: #E2F0FF;
  width: 96%;
  height: auto;
  overflow: hidden;
  text-align: left;
  position: absolute;
  z-index: 1;
}
.main_background> p,.main_background> div{
  width: 60%;
  margin: 0 auto;
}
.main_background > #punish_p {
  margin-top: 48px;
  color: rgba(8, 40, 105, 1);
  font-size: 28px;
  font-weight: 900;
}
.main_background > #punish_p + p {
  color: rgba(8, 40, 105, 1);
  font-size: 16px;
  margin: 14px auto;
}

.main_background1> p,.main_background1> div{
  width: 60%;
  margin: 0 auto;
}
.main_background1 > #punish_p {
  margin-top: 48px;
  color: rgba(8, 40, 105, 1);
  font-size: 28px;
  font-weight: 900;
}
.main_background1 > #punish_p + p {
  color: rgba(8, 40, 105, 1);
  font-size: 16px;
  margin: 14px auto;
}

.punish_search{
  text-align: center;
}
.el-checkbox-group {
  display: inline-block;
  vertical-align: top;

}
/* 主体展开 */
.el-checkbox-group {
  width: 88%;
}
.el-checkbox:last-of-type {
  margin-right: 17px;
}
.el-form-item{
  margin-bottom: 0px;
}
.main18 {
  width: 1203px;
  height: 1px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdad15fa71fcebcac6d35f177823d1ab5198025734510cbcaabc2e2a9bdf36d7a)
  100% no-repeat;
  position: absolute;
  top: 216px;
  left: 1.91rem;
}
</style>
<style lang="scss" >

</style>